import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../components/layout';
import { SygnaLogoHeader } from '../components/common';
import svg404 from '../images/404.svg';

const useStyles = makeStyles(() => ({
  content: {
    background: 'white',
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  imageContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-100px',
    marginLeft: '-125px',
  },
  image: {
    width: '250px',
    height: '200px',
  },
}));

function NotFoundPage() {
  const classes = useStyles();
  return (
    <Layout maxWidth="100%">
      <div className={classes.content}>
        <SygnaLogoHeader />
        <div id="main">
          <div className={classes.imageContainer}>
            <img className={classes.image} src={svg404} alt="404" />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
